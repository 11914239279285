<template>
  <v-app-bar
    color="transparent"
    style="height:70px;background-image:linear-gradient(180deg,#fafafa 70% ,transparent );"
    density="compact"
    flat
  >
    <v-app-bar-nav-icon
      @click.stop="toggleDrawerExpansion"
      v-show="!$vuetify.display.smAndUp"
    ></v-app-bar-nav-icon>

    <a
      to=""
      style="padding:0;padding-left:15px;padding-right:5px;text-decoration:none;"
    >
      <h3 class="text-h6 font-weight-light text-primary">
        <strong style="color:#053768">Trust</strong>Monitor
      </h3>
    </a>
    <span
      class="text-subtitle-1 font-weight-light text-pink"
      style="font-size:.85em !important;"
      v-if="instance"
    >
      <v-breadcrumbs color="primary" :items="crumbs">
        <template v-slot:divider>
          <v-icon color="primary" icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:title="{ item }">
          <v-breadcrumbs-item :to="item.href">
            {{ item.text }}
          </v-breadcrumbs-item></template
        >
      </v-breadcrumbs>
    </span>

    <v-spacer></v-spacer>

    <UserUpload></UserUpload>

    <user-display></user-display>

    <v-progress-linear
      :active="page_loading"
      :indeterminate="page_loading"
      position="absolute"
      color="primary-accent-4"
      location="top"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
import axios from 'axios'
import UserDisplay from './UserDisplay.vue'
import UserUpload from './UserUpload.vue'

export default {
  components: { UserDisplay, UserUpload },
  data: function() {
    return {
      dialog: false,
      search: null,
      count: null,
      items: [],
      timer: false,
      loading: true,
      timeout: 30000
    }
  },
  computed: {
    crumbs() {
      return this.$store.getters.crumbs
    },
    show_breadcrumbs() {
      return !this.overlay || this.$route.path !== '/'
    },
    last_notification() {
      return this.$store.state.last_notification
    },
    snackbar_color() {
      return this.$store.state.snackbar_color
    },
    snackbar: {
      get: function() {
        return this.$store.state.notification_snackbar
      },
      set: function(newValue) {
        this.$store.state.notification_snackbar = newValue
      }
    },
    notifications() {
      return this.$store.getters.notifications
    },
    page_loading() {
      return this.$store.getters.page_loading
    },
    auth() {
      return this.$store.getters.ifAuthenticated
    },
    instance() {
      return this.$store.getters.instance
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(newValue) {
        this.$store.state.drawer = newValue
      }
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout')
    },
    toggleDrawerExpansion() {
      this.expand_sensors = false
      this.drawer = !this.drawer
    }
  },
  watch: {
    search(val) {
      this.dialog = true
      axios
        .get(this.$store.state.api_domain + '/certificates/?search=' + val)
        .then((response) => {
          this.count = response.data.count
          this.items = response.data.results.slice(0, 2)
        })
    }
  },
  mounted() {}
}
</script>
