<template>
  <div v-if="certificate.pki_certificate.ca_type > 0">
    <v-btn
      :to="to_link"
      class="ma-0 pa-0"
      variant="outlined"
      color="primary"
      style="height:40px;width:64px !important;float:left;border-radius: 3px !important"
      density="compact"
      stacked
    >
      <v-badge
        v-if="certificate.pki_certificate.display_type === 0"
        color="primary"
        location="bottom start"
        icon="mdi-anchor"
        offset-y="6"
        offset-x="6"
      >
        <v-icon size="x-large" color="secondary" style="width:64px" class="mt-0"
          >mdi-certificate-outline
        </v-icon>
      </v-badge>
      <v-badge
        v-else-if="certificate.pki_certificate.display_type === 2"
        color="primary"
        location="bottom start"
        icon="mdi-account"
        offset-y="6"
        offset-x="6"
      >
        <v-icon size="large" color="secondary" style="width:64px" class="mt-0"
          >mdi-certificate-outline
        </v-icon>
      </v-badge>
      <v-badge
        v-else-if="certificate.pki_certificate.display_type === 4"
        color="primary"
        location="bottom start"
        icon="mdi-server-security"
        offset-y="6"
        offset-x="6"
      >
        <v-icon size="large" color="secondary" style="width:64px" class="mt-0"
          >mdi-certificate-outline
        </v-icon>
      </v-badge>
      <v-badge
        v-else
        color="primary"
        location="bottom start"
        :content="display_badge"
        offset-y="4"
        :offset-x="8.25 * display_badge.length"
      >
        <v-icon size="large" color="secondary" style="width:64px" class="mt-0"
          >mdi-certificate-outline
        </v-icon>
      </v-badge>
    </v-btn>
  </div>
  <div v-else>
    <v-btn
      :to="to_link"
      class="ma-0 pa-0"
      variant="outlined"
      color="primary"
      style="height:40px;width:64px !important;float:left;border-radius: 3px 3px 3px 3px !important"
      density="compact"
      stacked
    >
      <v-badge
        v-if="certificate.pki_certificate.display_type === 2"
        color="primary"
        location="bottom start"
        icon="mdi-certificate-outline"
        offset-y="6"
        offset-x="6"
      >
        <v-icon size="large" color="secondary" style="width:64px" class="mt-0"
          >mdi-account
        </v-icon>
      </v-badge>
      <v-badge
        v-else-if="certificate.pki_certificate.display_type === 4"
        color="transparent"
        size="large"
        location="bottom start"
        icon="mdi-certificate-outline"
        offset-y="6"
        offset-x="6"
      >
        <v-icon size="large" color="secondary" style="width:64px" class="mt-0"
          >mdi-server
        </v-icon>
      </v-badge>
      <v-badge
        v-else
        color="primary"
        location="bottom start"
        :content="display_badge"
        offset-y="6"
        :offset-x="8.25 * display_badge.length"
      >
        <v-icon size="large" color="secondary" style="width:64px" class="mt-0"
          >mdi-certificate-outline
        </v-icon>
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
// util
import moment from 'moment/src/moment'

// components

export default {
  name: 'CertTile',
  props: ['certificate', 'local'],
  data: function() {
    return {
      shown: false,
      not_after: moment(this.certificate.pki_certificate.not_after).isAfter(
        moment()
      ),
      not_before: moment(this.certificate.pki_certificate.not_before).isBefore(
        moment()
      ),
      is_valid:
        moment(this.certificate.pki_certificate.not_after).isAfter(moment()) &&
        moment(this.certificate.pki_certificate.not_before).isBefore(moment()),
      cert_details: null
    }
  },
  computed: {
    display_badge() {
      let id = this.certificate.pki_certificate.display_type
      if (id === 1) {
        return 'CA'
      } else if (id === 2) {
        return 'EE'
      } else if (id === 3) {
        return 'OCSP'
      } else if (id === 4) {
        return ''
      } else {
        return ''
      }
    },
    to_link: function() {
      return '/certificate/' + this.certificate.pki_certificate.sha256
    },
    certificate_settings: function() {
      return this.$store.getters.certificate_settings
    },
    monitored: function() {
      if (this.certificate.pki_certificate.ca_type > 0) {
        return this.$store.state.monitored_cas.find(
          (ca) =>
            ca.pki_certificate.sha256 ===
            this.certificate.pki_certificate.sha256
        )
      } else {
        return false
      }
    }
  },
  methods: {}
}
</script>
