import app from '../main'
import { createStore } from 'vuex'
import { primaryApi } from './MonitorApis'
import router from '../router'
import moment from 'moment'
import eventData from './events.json'

export default createStore({
  state: {
    base_url: '',
    api_url: '',
    show_upload: false,
    user_upload: {},
    last_sensor_updates: [],
    crumbs: [],
    monitored_cas: [],
    nak_issued_uris: [],
    public_events: [],
    private_events: [],
    all_events: [],
    certificate_tiles: [],
    compare_certificates: [],
    user_groups: [],
    active_session: false,
    is_authenticated: 0,
    last_notification: null,
    notification_snackbar: false,
    error_snackbar: false,
    error_snackbar_message: 'Request Failed',
    page_loading: false,
    drawer: null,
    expand_sensors: false,
    uri_settings: null,
    show_group_dialog: false,
    editing_group: null,
    access_token: null,
    refresh_token: null,
    all_groups: [],
    overlay: true,
    overlay_text: 'loading...',
    overlay_style: 'loading',
    instance: null,
    oauth_settings: null,
    remote_instances: [],
    instance_configured: true,
    cert_day_options: [
      { value: 5001, title: '1 day' },
      { value: 5003, title: '3 days' },
      { value: 5007, title: '7 days' },
      { value: 5014, title: '14 days' },
      { value: 5030, title: '30 days' },
      { value: 5060, title: '60 days' },
      { value: 5090, title: '90 days' }
    ],
    searchUrl:
      '/search/?search_type=ca_certs&meta_tags=&expired=false&revoked=false&old=false',
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
      sensor_data: []
    },
    notifications: [],
    first_run: true,
    snackbar_color: 'success',
    eventids: eventData
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      app.config.globalProperties.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      console.log('message', message)
      if (message.channel === 'session') {
        let userData = message.user_info
        if (userData.is_active) {
          this.commit('setLocalAuthenticated', 1)
          state.active_session = {
            uuid: userData.uuid,
            is_active: userData.is_active,
            is_super: userData.organization.is_super,
            organization: userData.organization.uuid,
            org_admin: userData.org_admin || userData.organization.is_super,
            org_api_access:
              userData.org_api_access ||
              userData.organization.is_super ||
              userData.org_admin,
            org_ee_group_management:
              userData.org_ee_group_management ||
              userData.organization.is_super ||
              userData.org_admin,
            org_sensor_management:
              userData.org_sensor_management ||
              userData.organization.is_super ||
              userData.org_admin
          }
        } else {
          if (state.active_session) {
            this.state.is_authenticated = 0
            this.dispatch('logout')
          }
        }
        this.state.instance = message.instances.filter(
          (instance) => instance.base_url == this.state.base_url
        )[0]
        this.state.remote_instances = message.instances.filter(
          (instance) => instance.base_url !== this.state.base_url
        )
        state.overlay = false
      } else if (message.channel === 'user') {
        if (message.type === 'subscribed') {
          state.user_groups = message.groups
        } else if (message.type === 'group_updated') {
          //update group in user_groups
          let group = state.user_groups.find(
            (group) => group.group.uuid === message.group.uuid
          )

          if (group) {
            group.group = JSON.parse(JSON.stringify(message.group))
          }
        } else if (message.type === 'user_group_updated') {
          //update group in user_groups
          let group = state.user_groups.find(
            (group) => group.uuid === message.group.uuid
          )

          if (group) {
            group.ca_settings = message.group.ca_settings
            group.issued_ca_settings = message.group.issued_ca_settings
            group.crl_settings = message.group.crl_settings
            group.ocsp_settings = message.group.ocsp_settings
            group.aia_settings = message.group.aia_settings
            group.sia_settings = message.group.sia_settings
            group.ee_settings = JSON.parse(
              JSON.stringify(message.group.ee_settings)
            )
          }
        } else if (message.type === 'user_group_created') {
          state.user_groups.push(message.group)
        } else if (message.type === 'user_group_deleted') {
          let group = state.user_groups.find(
            (group) => group.uuid === message.group
          )
          // delete group from user_group
          if (group) {
            state.user_groups.splice(state.user_groups.indexOf(group), 1)
          }
        }
      } else if (message.channel === 'public_events') {
        if (message.type === 'subscribed') {
          state.public_events = message.events
        } else {
          state.public_events.push(message.entry)
          state.public_events
            .sort(function(x, y) {
              return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
            })
            .slice(0, 15)
        }

        this.dispatch('update_all_events')
      } else if (message.channel === 'org_events') {
        if (message.type === 'subscribed') {
          state.private_events = message.events
        } else {
          state.private_events.push(message.entry)
          state.private_events
            .sort(function(x, y) {
              return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
            })
            .slice(0, 15)
        }
        this.dispatch('update_all_events')
      } else if (message.channel === 'sensor_logs') {
        if (message.type === 'subscribed' && message.start_entries.length > 0) {
          state.socket.sensor_data = message.start_entries[0].data_json
        }
        if (message.type === 'created') {
          state.socket.sensor_data = message.entry.data_json
        }
      } else if (message.channel === 'monitored_cas') {
        state.monitored_cas = message.cas
      } else if (message.channel === 'sensors') {
        state.last_sensor_updates = message.start_entries
      } else if (message.channel.slice(0, 6) === 'sensor') {
        let sensor_id = message.channel.split('_')[1]
        let to_replace = state.last_sensor_updates[sensor_id].find(
          (e) => e.sensor_data_type === message.entry.sensor_data_type
        )
        if (to_replace) {
          state.last_sensor_updates[sensor_id].splice(
            state.last_sensor_updates[sensor_id].indexOf(to_replace),
            1
          )
          state.last_sensor_updates[sensor_id].push(message.entry)
        }
      } else if (message.channel === 'upload') {
        if (message.type === 'new_upload') {
          //state.show_upload = true
          state.user_upload = { certs: [], size: message.size }
        } else {
          state.user_upload['certs'].push(message.certificate)
        }
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true
    },
    clearAuth(state) {
      this.commit('setLocalAuthenticated', 0)
      localStorage.setItem('is_authenticated', JSON.stringify(0))
      state.active_session = null
    },
    setLocalAuthenticated(state, value) {
      state.is_authenticated = value
      localStorage.setItem('is_authenticated', JSON.stringify(value))
    },
    /*
     * @name - add_to_compare
     * @description - add a cert to compare
     */
    add_to_compare(state, sha256 = null) {
      if (sha256) {
        primaryApi.get('certificates/' + sha256 + '/info/').then((response) => {
          state.compare_certificates.push(response.data)
        })
      }
    },
    /*
     * @name - remove_from_compare
     * @description - remove a cert from the compare list
     */
    remove_from_compare(state, sha256 = null) {
      const certs = state.compare_certificates

      for (let t = 0; t < certs.length; t++) {
        const cert = certs[t]
        const certhash = cert.cert_info.info.hashes.sha256
        if (certhash === sha256) {
          state.compare_certificates.splice(t, 1)
        }
      }
    },
    /*
     * @name - clear_compare
     * @description - clear all compare certs
     */
    clear_compare(state) {
      state.compare_certificates = []
    },
    updateSearchUrl(state, newUrl = null) {
      if (newUrl) {
        state.searchUrl = newUrl
      } else {
        state.searchUrl =
          '/search/?search_type=ca_certs&meta_tags=&expired=false&revoked=false&old=false'
      }
    }
  },
  actions: {
    async init({ commit }) {
      // Get most recent is_authenticated value from local storage on initial refresh
      const is_authenticated = JSON.parse(
        localStorage.getItem('is_authenticated')
      )
      // if no localStore item found, set to false by default
      if (!is_authenticated) {
        commit('setLocalAuthenticated', 0)
      }
      // Commit regardless of local storage so we know whether or not we're authenticated on initialization of store
      commit('setLocalAuthenticated', is_authenticated)
    },
    update_all_events() {
      this.state.all_events = this.state.private_events
        .concat(this.state.public_events)
        .sort(function(x, y) {
          return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
        })
        .slice(0, 10)
        .map((m) => {
          m.ago = moment(m.timestamp).fromNow()
          return m
        })
    },
    get_instances() {
      primaryApi.defaults.baseURL = this.state.api_url

      primaryApi
        .get('instances/')
        .then((response) => {
          if (response.data.count > 0) {
            //this instance is configured
            this.state.instance_configured = true
            // the primary instance is the one with the matching URL (the one we got the list from)
            this.state.instance = response.data.results.find(
              (instance) => instance.primary === true
            )
            this.state.remote_instances = response.data.results.filter(
              (instance) => instance.primary === false
            )
            this.state.oauth_settings = {
              client_id: this.state.instance.client_id,
              portal_url: this.state.instance.portal_url
            }
            app.config.globalProperties.$connect()
          } else {
            this.state.instance_configured = false
            this.state.overlay = false
          }
        })
        .catch((err) => {
          this.state.overlay_style = 'error'
          this.state.overlay_text = err
          this.state.overlay = true
        })
    },
    login({ commit }, authData) {
      app.config.globalProperties.$disconnect()

      primaryApi
        .post('login/', authData)
        .then((res) => {
          commit('setLocalAuthenticated', 1)
          document.cookie = 'csrftoken=' + res.data.csrf + ';path=/'
          app.config.globalProperties.$connect()
        })
        .catch((err) => {
          commit('clearAuth')
          this.state.overlay_style = 'error'
          this.state.overlay_text = err
          this.state.overlay = true
        })
    },
    logout({ commit }) {
      app.config.globalProperties.$disconnect()

      primaryApi.get('logout/').then(() => {
        commit('clearAuth')
        router.push('/').catch(() => {})
        this.dispatch('get_instances')
      })
    }
  },
  modules: {},
  plugins: [],
  getters: {
    oauth_settings: (state) => state.oauth_settings,
    sensors(state) {
      if (!state.instance) return []
      let sensors = []
      for (let i = 0; i < state.instance.sites.length; i++) {
        let site = state.instance.sites[i]
        if (site.sensors.length > 0) {
          sensors = sensors.concat(site.sensors)
        }
      }
      state.remote_instances.forEach((instance) => {
        sensors = sensors.concat(instance.sites[0].sensors)
      })
      return sensors
    },
    show_upload: (state) => state.show_upload,
    user_upload: (state) => state.user_upload,
    monitored_cas: (state) => state.monitored_cas,
    last_sensor_updates: (state) => state.last_sensor_updates,
    crumbs: (state) => state.crumbs,
    error_snackbar: (state) => state.error_snackbar,
    error_snackbar_message: (state) => state.error_snackbar_message,
    eventids: (state) => state.eventids,
    nak_issued_uris: (state) => state.nak_issued_uris,
    notifications: (state) => state.notifications,
    public_events: (state) => state.public_events,
    private_events: (state) => state.private_events,
    all_events: (state) => state.all_events,
    instance_configured: (state) => state.instance_configured,
    compare_certificates: (state) => state.compare_certificates,
    active_session: (state) => state.active_session,
    drawer: (state) => state.drawer,
    expand_sensors: (state) => state.expand_sensors,
    page_loading: (state) => state.page_loading,
    instance: (state) => state.instance,
    remote_instances: (state) => state.remote_instances,
    all_groups: (state) => state.all_groups,
    user_groups: (state) => state.user_groups,
    monitored_ees(state) {
      let group_settings = state.user_groups.map((e) => {
        let result = []
        if (e.group.group_type === 1) {
          // NAK group has 2 CA groups
          if (e.ca_settings.length) {
            result = result.concat(
              e.group.cas.reduce((a, v) => ({ ...a, [v]: e.ca_settings }), {})
            )
          }
          if (e.issued_ca_settings.length) {
            result = result.concat(
              e.group.issued_cas.reduce(
                (a, v) => ({ ...a, [v]: e.issued_ca_settings }),
                {}
              )
            )
          }
        } else if (e.group.group_type === 2) {
          // PATH group has 1 CA group
          if (e.ca_settings.length) {
            result = result.concat(
              e.group.cas.reduce((a, v) => ({ ...a, [v]: e.ca_settings }), {})
            )
          }
        } else {
          // EE groups don't have CAs
        }

        return result
      })
      // combine group_settings
      var output = {}
      group_settings.forEach(function(group) {
        group.forEach(function(item) {
          for (const [key, value] of Object.entries(item)) {
            if (output[key]) {
              output[key] = output[key].concat(value)
            } else {
              output[key] = value
            }
          }
        })
      })
      return output
    },
    certificate_tiles: (state) => state.certificate_tiles,
    searchUrl: (state) => state.searchUrl
  }
})
