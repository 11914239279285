import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import VueNativeSock from 'vue-native-websocket-vue3'

const app = createApp(App)

navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
})

fetch('/config.json')
  .then((response) => response.json())
  .then((config) => {
    const baseUrl = config['BASE_URL']
    app.provide('BASE_URL', baseUrl)

    app.config.globalProperties.$filters = {
      dateTimeReadable(dateTime, dateTimeFormat) {
        return moment(dateTime).format(dateTimeFormat)
      },
      dateTimeFromNow(dateTime, showSuffix = false) {
        return moment(dateTime).fromNow(showSuffix)
      },
      round(val, decimals) {
        let validToRound = false
        if (typeof val === 'number') {
          validToRound = true
        } else {
          val = parseFloat(val)
          if (!isNaN(val)) {
            validToRound = true
          }
        }
        if (validToRound) {
          return +(
            Math.round(+(val.toFixed(decimals) + 'e+' + decimals)) +
            'e-' +
            decimals
          )
        } else {
          return 0
        }
      }
    }

    app.use(router)
    app.use(store)
    app.use(vuetify)

    store.dispatch('init')
    let ws_url = baseUrl.replace('http', 'ws') + '/api/v1/ws/session/'

    app.use(VueNativeSock, ws_url, {
      connectManually: true,
      store: store,
      format: 'json',
      reconnection: true, // (Boolean) whether to reconnect automatically (false)
      reconnectionAttempts: 0, // (Number) number of reconnection attempts before giving up (Infinity),
      reconnectionDelay: 500 // (Number) how long to initially wait before attempting a new (1000) });
    })

    app.mount('#app')
  })

export default app
