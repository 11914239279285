import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'

const vuetify = createVuetify({
  blueprint: md3,
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        dark: true,
        colors: {
          accent: '#7192B0',
          error: '#aa2123',
          primary: '#7192B0',
          secondary: '#053768',
          'sheet-background': '#fafafa',
          'card-background': '#fafafa'
        }
      },
      light: {
        dark: false,
        colors: {
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          primary: '#7192B0',
          secondary: '#053768',
          background: '#fafafa',
          surface: '#fafafa'
        }
      }
    }
  },
  components: {
    ...components
  },
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: 'Results not found'
    }
  },
  directives
})

export default vuetify
