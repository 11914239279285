<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-overlay
      v-model="overlay"
      app
      class="justify-center align-center"
      scrim="primary"
      style="z-index: 9002"
      persistent
    >
      <template v-if="overlay_style === 'loading'">
        <svg
          viewBox="-2 -5 90 46"
          width="100"
          height="50"
          style="margin-top:15px;width:100%"
          v-if="!goDark"
        >
          <path
            class="path3"
            fill="#e4e4e4"
            stroke="rgba(5, 55, 104,.6)"
            stroke-width="2"
            d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
          />
          <path
            class="path3"
            fill="#e4e4e4"
            stroke="rgba(113, 146, 176,.6)"
            stroke-width="2"
            d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
          />
        </svg>
        <v-divider class="mb-2"></v-divider>
        <div style="width:100%;text-align:center;">
          <span style="font-size:.85em;">{{ overlay_text }}</span>
        </div>
      </template>
      <template v-else-if="overlay_style === 'error'">
        <svg
          viewBox="-2 -5 90 46"
          width="100"
          height="50"
          style="margin-top:15px;width:100%"
        >
          <path
            fill="#ddd"
            stroke="rgba(5, 55, 104,.6)"
            stroke-width="2"
            d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
          />
          <path
            fill="#ddd"
            stroke="rgba(5, 146, 176,.6)"
            stroke-width="2"
            d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
          />
        </svg>

        <v-divider class="mb-2"></v-divider>
        <div style="width:100%;text-align:center;">
          <span style="font-size:.85em;color: red;font-weight:bold;">
            {{ overlay_text }}
            <br />
            <v-btn @click="overlay = false" color="primary" class="mt-2"
              >Try Again</v-btn
            >
          </span>
        </div>
      </template>
    </v-overlay>

    <Menu />
    <Header />

    <v-main class="pb-8">
      <v-container class="pa-0 ml-0" fluid>
        <router-view v-slot="{ Component, route }">
          <component :is="Component" :key="route.path" />
        </router-view>
      </v-container>
    </v-main>

    <v-snackbar
      location="bottom right"
      v-model="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="$store.state.error_snackbar"
      color="error"
      elevation="24"
      location="top"
    >
      <v-icon class="mr-2">
        mdi-alert
      </v-icon>
      {{ $store.state.error_snackbar_message }}

      <template v-slot:actions>
        <v-btn variant="text" @click="$store.state.error_snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="$store.state.success_snackbar"
      color="success"
      elevation="24"
      location="top"
    >
      <v-icon class="mr-2">
        mdi-check-circle
      </v-icon>
      {{ $store.state.success_snackbar_message }}

      <template v-slot:actions>
        <v-btn variant="text" @click="$store.state.success_snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-footer app elevation="1">
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="text-center">
          <template v-if="!this.$store.state.socket.isConnected">
            Not connected
          </template>

          {{ new Date().getFullYear() }} — <strong>CertiPath, Inc.</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { inject } from 'vue'
import Header from './components/Header'
import Menu from './components/Menu'
import update from './components/mixins/update'

export default {
  name: 'App',

  components: {
    Header,
    Menu
  },

  data: () => ({
    main_loading: true,
    compare_count: 1,
    error_snackbar: false,
    error_snackbar_message: 'Error',
    success_snackbar: false,
    success_snackbar_message: 'Success',
    main_snackbar: false,
    loading: true,
    next_tile_url: null,
    logged_in: false,
    goDark: false,
    main_select: null,
    main_search: null,
    main_items: [],
    main_dialog: false,
    notifications: false,
    main_count: null,
    sound: true,
    widgets: false
  }),
  mixins: [update],
  computed: {
    show_upload: {
      get() {
        return this.$store.state.show_upload
      },
      set(value) {
        this.$store.state.show_upload = value
      }
    },
    user_upload: function() {
      return this.$store.getters.user_upload
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    overlay: {
      get() {
        return this.$store.state.overlay
      },
      set(newValue) {
        this.$store.state.overlay = newValue
      }
    },
    overlay_text() {
      return this.$store.state.overlay_text
    },
    overlay_style() {
      return this.$store.state.overlay_style
    },
    group_dialog() {
      return this.$store.state.show_group_dialog
    },
    editing_group() {
      return this.$store.state.editing_group
    },
    auth() {
      return this.$store.getters.ifAuthenticated
    }
  },
  created() {
    const BASE_URL = inject('BASE_URL')
    this.$store.state.base_url = BASE_URL
    this.$store.state.api_url = BASE_URL + '/api/v1/'
    this.$store.dispatch('get_instances')
  }
}
</script>

<style lang="scss">
@use 'vuetify/settings';

html {
  // Fix always scrollbar shown.
  overflow-y: auto;
  // Modern scrollbar style
  scrollbar-width: thin;
  scrollbar-color: map-get(settings.$grey, 'lighten-2')
    map-get(settings.$grey, 'base');
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.1);
  background-color: map-get(settings.$grey, 'lighten-2');
}
::-webkit-scrollbar-thumb {
  border-radius: 0.5rem !important;
  background-color: map-get(settings.$grey, 'base');
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.1);
}
// Fix app-bar's progress-bar
.v-app-bar .v-progress-linear {
  position: absolute;
  bottom: 0;
}
</style>
