<template>
  <div>
    <template v-if="!active_session"> </template>
    <template v-else-if="user_upload.size > 0">
      <v-dialog v-if="$vuetify.display.smAndDown" fullscreen v-model="menu">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" text>
            <v-icon class="mr-1" size="small" color="primary"
              >mdi-account</v-icon
            >
            {{ user_upload }}
            <v-icon class="mr-n3 ml-1" color="#1073ad">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card style="width: 100%" class="mt-0">
          <v-toolbar dark color="secondary">
            <v-toolbar-title>
              <v-icon class="ma-0 pa-0 mr-2" large color="primary"
                >mdi-account</v-icon
              >
              {{ user_upload }} </v-toolbar-title
            ><v-spacer></v-spacer
            ><v-btn icon dark @click="menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-sheet style="height: 3px" color="accent"></v-sheet>

          <v-card-text class="ma-0 pa-0">
            <v-list subheader>
              <v-list-subheader>Your Account</v-list-subheader>
              <v-list-item
                to="/Account"
                @click=";(menu = false), (selected = 0)"
              >
                <v-list-item><v-icon> mdi-shield-account </v-icon></v-list-item>
                <div>
                  <v-list-item-title>Account Management</v-list-item-title>
                </div>
              </v-list-item>

              <v-list-item
                to="/History"
                @click=";(menu = false), (selected = 0)"
              >
                <v-list-item><v-icon> mdi-history </v-icon></v-list-item>
                <div>
                  <v-list-item-title>History</v-list-item-title>
                </div>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item><v-icon> mdi-logout </v-icon></v-list-item>
                <div>
                  <v-list-item-title>Sign Out</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-menu v-else v-model="menu" min-width="350">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" text>
            <v-icon class="mr-1" size="small" color="primary"
              >mdi-upload</v-icon
            >
            <template v-if="user_upload.certs.length < user_upload.size">
              <v-progress-circular
                :size="25"
                :value="(100 * user_upload.certs.length) / user_upload.size"
                color="primary"
                class="mb-0"
              >
              </v-progress-circular>
            </template>
            <template v-else>
              {{ user_upload.size }}
              <v-icon size="x-small" color="success">mdi-check-circle</v-icon>
            </template>
          </v-btn>
        </template>

        <v-card
          style="width: 100%; background: transparent"
          class="ma-0 pa-0"
          variant="flat"
        >
          <v-sheet style="height: 3px" color="accent"></v-sheet>

          <v-card-text class="ma-0 pa-0">
            <v-list subheader density="compact">
              <template v-if="active_session">
                <v-list-subheader>Certificate Upload</v-list-subheader>
                <CertificateTable :certificates="user_upload.certs">
                </CertificateTable>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>

<script>
import CertificateTable from './CertificateTable.vue'
export default {
  name: 'UserUpload',
  props: [],
  data: () => ({
    selected: null,
    menu: false,
    message: false,
    hints: true,
    tab: 0
  }),
  methods: {},
  computed: {
    show_upload: {
      get() {
        return this.$store.state.show_upload
      },
      set(value) {
        this.$store.state.show_upload = value
      }
    },
    user_upload: function() {
      return this.$store.getters.user_upload
    },
    active_session() {
      return this.$store.getters.active_session
    },
    overlay() {
      return this.$store.getters.overlay
    },
    display_name() {
      return null
    }
  },
  components: { CertificateTable }
}
</script>
