<template>
  <v-btn
    class="ma-0 mr-0 pa-2 ml-1 mb-1"
    color="primary"
    label
    size="small"
    variant="outlined"
    title="Download File"
    @click="downloadFile"
    :loading="downloadLoading"
    :disabled="downloadLoading || !sha256"
  >
    <v-icon size="small">mdi-download</v-icon>
  </v-btn>
</template>

<script>
import { primaryApi } from '../store/MonitorApis'
import fileDownload from 'js-file-download'

export default {
  name: 'DownloadFileButton',
  props: {
    sha256: {
      type: String
    },
    fileExtension: {
      default: 'crl',
      required: true,
      type: String
    },
    filename: {
      type: String
    }
  },
  data: function() {
    return {
      downloadLoading: false
    }
  },
  methods: {
    downloadFile() {
      this.downloadLoading = true
      // let apiUrl = this.$store.state.api_url
      let downloadUrl = `files/${this.sha256}/download`
      if (this.filename) {
        downloadUrl += `/?filename=${this.filename}`
      }
      primaryApi
        .get(downloadUrl, {
          responseType: 'blob'
        })
        .then((resp) => {
          if (resp.status == '200') {
            fileDownload(resp.data, `${this.filename}.${this.fileExtension}`)
            // window.open(`${apiUrl}${downloadUrl}`, '_blank')
          }
        })
        .catch((err) => {
          console.log(
            `Error downloading ${this.filename}.${this.fileExtension} with sha256: ${this.sha256}`
          )
          this.$store.state.error_snackbar = true
          this.$store.state.error_snackbar_message = err
          console.error(err)
        })
        .finally(() => {
          this.downloadLoading = false
        })
    }
  }
}
</script>
