<template>
  <v-navigation-drawer
    :rail="$vuetify.display.smAndUp && mini"
    rail-width="62"
    order="0"
    v-model="drawer"
    :location="$vuetify.display.smAndUp ? 'left' : 'bottom'"
    :permanent="$vuetify.display.smAndUp || false"
  >
    <v-sheet v-if="$vuetify.display.smAndUp">
      <v-btn
        block
        @click.stop="toggleDrawerExpansion"
        class="ml-0"
        elevation="0"
        variant="text"
      >
        <v-icon color="primary" class="toggleUpDown" :class="{ rotate: mini }">
          mdi-menu-left
        </v-icon>
      </v-btn>
    </v-sheet>
    <v-divider></v-divider>
    <MenuDrawerSection :items="filteredGeneralMenuItems" :mini="mini" />
    <template v-if="active_session">
      <v-divider></v-divider>
      <MenuDrawerSection
        :items="filteredReportingMenuItems"
        title="Reporting"
        :mini="mini"
      />
      <v-divider></v-divider>
      <MenuDrawerSection
        :items="filteredMonitoringGroupsMenuItems"
        title="Monitoring Groups"
        :mini="mini"
      />
    </template>
    <template
      v-if="
        active_session && (active_session.is_super || active_session.org_admin)
      "
    >
      <v-divider></v-divider>
      <MenuDrawerSection
        :items="filteredAdminMenuItems"
        title="Administration"
        :mini="mini"
      />
    </template>
    <template v-slot:append v-if="active_session">
      <v-sheet
        link
        height="40px"
        @click="toggleSensorExpansion"
        style="cursor:pointer; border-top: 1px solid rgba(0, 0, 0, 0.12);"
      >
        <v-tooltip v-if="mini" activator="parent" location="start">
          <span>Active Sensors</span>
        </v-tooltip>
        <v-list-item>
          <template v-slot:prepend>
            <v-badge
              :color="offline_count ? 'error' : 'success'"
              :value="offline_count"
              :content="offline_count"
              overlap
            >
              <v-icon color="primary">mdi-access-point</v-icon>
            </v-badge>
          </template>
          <template v-slot:title>
            <v-list-item-title
              class="text-subtitle-2 font-weight-light text-primary"
              style="padding-left: 2.25em;"
            >
              Active Sensors
            </v-list-item-title>
          </template>
        </v-list-item>
      </v-sheet>
      <v-expand-transition>
        <v-container v-show="expand_sensors && drawer">
          <v-sheet density="compact" :key="s.id" v-for="s in sites">
            <template v-if="s.sensors.length > 0">
              <v-card
                variant="flat"
                v-for="sensor in s.sensors"
                :key="sensor.uuid"
                class="text-caption font-weight-light text-primary"
              >
                <template
                  v-if="
                    last_sensor_updates[sensor.uuid] &&
                      last_sensor_updates[sensor.uuid].length > 0
                  "
                >
                  <template
                    v-if="
                      is_online(last_sensor_updates[sensor.uuid][0].created_at)
                    "
                  >
                    <span class="text-caption text-success">ONLINE</span>
                    {{ sensor.name }}
                    <br />
                    Last Update:
                    {{
                      $filters.dateTimeFromNow(
                        last_sensor_updates[sensor.uuid][0].created_at
                      )
                    }}
                    <br />
                  </template>
                  <template v-else>
                    <span
                      v-if="sensor.http || sensor.ldap || sensor.ocsp"
                      class="text-caption font-weight-bold text-error"
                    >
                      OFFLINE
                    </span>
                    <span
                      v-else
                      class="text-caption font-weight-bold text-gray"
                    >
                      DISABLED
                    </span>
                    {{ sensor.name }}
                    <br />
                    Last Update:
                    <template
                      v-if="last_sensor_updates[sensor.uuid][0].created_at"
                    >
                      {{
                        $filters.dateTimeFromNow(
                          last_sensor_updates[sensor.uuid][0].created_at
                        )
                      }}
                    </template>
                    <template v-else>
                      Never
                    </template>
                  </template>
                </template>
                <template v-else>
                  <span class="text-caption font-weight-bold text-error">
                    OFFLINE
                  </span>
                  {{ sensor.name }}
                  <br />
                  Last Update: Never
                </template>
              </v-card>
            </template>
          </v-sheet>
        </v-container>
      </v-expand-transition>
    </template>
  </v-navigation-drawer>
  <!-- Unused / not built routes 
    <v-list-item
      density="compact"
      link
      disabled
      style="display:none;"
      title="FPKI"
      prepend-icon="mdi-graph-outline"
    >
      <template v-slot:prepend>
        <v-icon color="primary"></v-icon>
      </template>
    </v-list-item>

    <v-list-item
      density="compact"
      link
      href="/dns/"
      disabled
      style="display:none;"
      title="DNS Monitoring"
      prepend-icon="mdi-lan"
    >
      <template v-slot:prepend>
        <v-icon color="primary"></v-icon>
      </template>
    </v-list-item>

    <v-list-item
      density="compact"
      link
      disabled
      style="display:none;"
      title="Certificate Transparency"
      prepend-icon="mdi-dns"
    >
      <template v-slot:prepend>
        <v-icon color="primary"></v-icon>
      </template>
    </v-list-item> -->
</template>

<script>
import moment from 'moment'
import MenuDrawerSection from './MenuDrawerSection.vue'

export default {
  name: 'Menu',
  components: { MenuDrawerSection },
  data: function() {
    return {
      mini: true,
      reportingMenuItems: [
        {
          title: 'Availability',
          to: '/reporting/availability/',
          icon: 'mdi-percent-circle',
          tooltip: 'Availability',
          showOnActiveSession: true
        },
        {
          title: 'Expiration',
          to: '/reporting/expiration/',
          icon: 'mdi-chart-bar',
          tooltip: 'Expiration',
          showOnActiveSession: true
        }
        // {
        //   title: 'Event',
        //   to: '/reporting/event/',
        //   icon: 'mdi-chart-bar',
        //   tooltip: 'Event',
        //   showOnActiveSession: true
        // }
      ],
      monitoringGroupMenuItems: [
        {
          title: 'Certification Authorities',
          to: '/groups/ca/',
          icon: 'mdi-certificate-outline',
          tooltip: 'Certification Authorities',
          showOnActiveSession: true
        },
        {
          title: 'Users & Devices',
          to: '/groups/ee/',
          icon: 'mdi-account-cog',
          tooltip: 'Users & Devices',
          showOnActiveSession: true
        }
      ],
      adminMenuItems: [
        {
          title: 'API Access',
          to: '/admin/api/',
          icon: 'mdi-api',
          tooltip: 'API Access',
          showOnActiveSession: true
        },
        {
          title: 'Sites & Sensors',
          to: '/admin/sites/',
          icon: 'mdi-access-point',
          tooltip: 'Sites & Sensors',
          showOnActiveSession: true
        },
        {
          title: 'User Management',
          to: '/admin/users/',
          icon: 'mdi-account-group',
          tooltip: 'User Management',
          showOnActiveSession: true
        },
        {
          title: 'Instance',
          to: '/admin/instance/',
          icon: 'mdi-wrench',
          tooltip: 'Instance',
          showOnActiveSession: true
        }
      ]
    }
  },
  watch: {},
  methods: {
    is_online(at_time) {
      if (!at_time) return false
      return moment(at_time).isAfter(moment().subtract(5, 'minutes'))
    },
    toggleDrawerExpansion() {
      this.mini = !this.mini
      if (this.mini) {
        this.expand_sensors = false
      }
    },
    toggleSensorExpansion() {
      let expandTimeOut = 0
      if (this.mini || !this.$vuetify.display.smAndUp) {
        this.mini = !this.mini
        expandTimeOut = 250
      }
      setTimeout(() => {
        this.expand_sensors = !this.expand_sensors
      }, expandTimeOut)
    }
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(newValue) {
        this.$store.state.drawer = newValue
      }
    },
    expand_sensors: {
      get() {
        return this.$store.state.expand_sensors
      },
      set(newValue) {
        this.$store.state.expand_sensors = newValue
      }
    },
    filteredGeneralMenuItems() {
      let filteredItems = this.generalMenuItems.filter((item) => {
        if (this.active_session) {
          return item.showOnActiveSession === true
        } else {
          return item.showOnActiveSession === false || item.showAlways
        }
      })
      return filteredItems
    },
    filteredReportingMenuItems() {
      let filteredItems = this.reportingMenuItems.filter((item) => {
        if (this.active_session) {
          return item.showOnActiveSession === true
        } else {
          return item.showOnActiveSession === false
        }
      })
      return filteredItems
    },
    filteredMonitoringGroupsMenuItems() {
      let filteredItems = this.monitoringGroupMenuItems.filter((item) => {
        if (this.active_session) {
          return item.showOnActiveSession === true
        } else {
          return item.showOnActiveSession === false
        }
      })
      return filteredItems
    },
    filteredAdminMenuItems() {
      let filteredItems = this.adminMenuItems.filter((item) => {
        if (this.active_session) {
          return item.showOnActiveSession === true
        } else {
          return item.showOnActiveSession === false
        }
      })
      return filteredItems
    },
    generalMenuItems() {
      return [
        {
          to: '/',
          icon: 'mdi-monitor-dashboard',
          title: 'Dashboard',
          tooltip: 'Dashboard',
          showOnActiveSession: true
        },
        {
          to: '/',
          icon: 'mdi-login',
          title: 'Log In',
          tooltip: 'Log In',
          showOnActiveSession: false
        },
        {
          to: '/messages',
          icon: 'mdi-email',
          title: 'Message Center',
          tooltip: 'Message Center',
          showOnActiveSession: true
        },
        {
          to: '/trustexplorer',
          icon: 'mdi-graphql',
          title: 'TrustExplorer',
          tooltip: 'TrustExplorer',
          showOnActiveSession: true,
          showAlways: true
        },
        {
          to: this.searchUrl,
          icon: 'mdi-magnify',
          title: 'Advanced Search',
          tooltip: 'Advanced Search',
          showOnActiveSession: false || true,
          showAlways: true
        },
        {
          to: '/upload/',
          icon: 'mdi-upload',
          title: 'Upload Certificates',
          tooltip: 'Upload Certificates',
          showOnActiveSession: true
        },
        {
          to: '/users/',
          icon: 'mdi-account-multiple',
          title: 'Manage Users',
          tooltip: 'Manage Users',
          showOnActiveSession: false,
          disabled: true
        },
        {
          to: '/compare/',
          icon: 'mdi-file-compare',
          title: 'Certificate Comparison',
          tooltip: 'Certificate Comparison',
          badge: {
            condition: this.compare_count > 0, // replace with your actual condition
            content: this.compare_count
          },
          showOnActiveSession: true,
          showAlways: true
        }
      ]
    },
    offline_count() {
      if (!this.last_sensor_updates) return 0
      let n = 0
      // sensors which have previous updates
      for (const [key, value] of Object.entries(this.last_sensor_updates)) {
        console.debug(key, value)
        if (value.length > 0) {
          if (!this.is_online(value[0].created_at)) n++
        }
      }
      // sensors without previous updates
      // for (const [key, value] of Object.entries(this.sensors)) {
      //   console.log(key, value)
      //   if (this.sensors[key] in this.last_sensor_updates) n++
      // }
      return n
    },
    last_sensor_updates() {
      return this.$store.getters.last_sensor_updates
    },
    remote_instances() {
      return this.$store.getters.remote_instances
    },
    sites: function() {
      let all_sites = []
      if (this.$store.getters.instance) {
        all_sites = this.$store.getters.instance.sites
      }
      this.remote_instances.forEach((instance) => {
        all_sites = all_sites.concat(instance.sites)
      })
      return all_sites
    },
    compare_count: function() {
      return this.$store.getters.compare_certificates.length
    },
    active_session() {
      return this.$store.getters.active_session
    },
    searchUrl: function() {
      return this.$store.getters.searchUrl
    },
    is_authenticated() {
      return this.$store.state.is_authenticated
    }
  }
}
</script>

<style>
.toggleUpDown {
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(-180deg);
}
</style>
