<template>
  <v-list :nav="true" density="compact">
    <v-list-item-title
      v-if="!mini && title"
      class="text-primary text-subtitle-2"
      >{{ title }}</v-list-item-title
    >
    <v-list-item
      v-for="item in items"
      :key="item.title"
      :to="item.to"
      :link="true"
      :disabled="item.disabled || false"
      :style="{ display: item.disabled ? 'none' : '' }"
      density="compact"
    >
      <v-tooltip v-if="mini" activator="parent" location="start">
        <span>{{ item.tooltip }}</span>
      </v-tooltip>
      <template v-slot:prepend>
        <v-icon color="primary">{{ item.icon }}</v-icon>
        <v-badge
          v-if="item.badge && item.badge.condition"
          :content="item.badge.content"
          color="secondary"
          overlap
        ></v-badge>
      </template>
      <template v-slot:title>
        <v-list-item-title
          class="text-subtitle-2 font-weight-light text-primary"
        >
          {{ item.title }}
        </v-list-item-title>
      </template>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: 'MenuDrawerSection',
  props: {
    items: {
      type: Object,
      required: true
    },
    title: {
      type: String
    },
    mini: {
      type: Boolean,
      required: true
    }
  }
}
</script>
