<template>
  <div>
    <template v-if="!active_session"> </template>
    <template v-else>
      <v-menu v-model="menu" width="350">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" text>
            <v-icon class="mr-1" size="small" color="primary"
              >mdi-account</v-icon
            >
            {{ display_name }}
            <v-icon class="mr-n3 ml-1" color="#1073ad">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card flat>
          <v-sheet style="height: 3px" color="accent"></v-sheet>

          <v-card-text class="ma-0 pa-0">
            <v-list subheader density="compact">
              <template v-if="active_session">
                <v-list-subheader>Your Account</v-list-subheader>
                <v-list-item @click="logout">
                  <v-list-item-title>
                    <v-icon> mdi-logout </v-icon> Sign Out</v-list-item-title
                  >
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UserDisplay',
  props: [],

  data: () => ({
    selected: null,
    menu: false,
    message: false,
    hints: true,
    tab: 0
  }),
  methods: {
    logout() {
      this.menu = false
      this.$store.dispatch('logout')
    }
  },
  computed: {
    active_session() {
      return this.$store.getters.active_session
    },
    overlay() {
      return this.$store.getters.overlay
    },
    display_name() {
      return null
    }
  }
}
</script>
