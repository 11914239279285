import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const ifNotAuthenticated = (to, from, next) => {
  if (store.state.is_authenticated) {
    next()
    return
  }
  store.state.error_snackbar = true
  store.state.error_snackbar_message = 'Please log in to continue'
  next({
    name: 'Home'
  })
}

const routeOptions = [
  { path: '/', name: 'Home', props: true },
  { path: '/upload', name: 'Upload', beforeEnter: ifNotAuthenticated },
  {
    path: '/groups/ca/',
    name: 'groups/Groups',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/groups/ee/',
    name: 'groups/EEGroups',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/search',
    name: 'Search',
    props: (route) => ({ query: route.query.q })
  },
  { path: '/compare', name: 'Compare' },
  {
    path: '/certificate/:sha256',
    name: 'Certificate',
    beforeEnter: ifNotAuthenticated
  },
  { path: '/url/:sha256', name: 'Url', beforeEnter: ifNotAuthenticated },
  { path: '/crl/:sha256', name: 'Crl', beforeEnter: ifNotAuthenticated },
  { path: '/event/:uuid', name: 'Event', beforeEnter: ifNotAuthenticated },
  { path: '/trustexplorer', name: 'TrustExplorer' },
  {
    path: '/reporting/availability',
    name: 'reports/Availability',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/reporting/expiration',
    name: 'reports/Expiration',
    beforeEnter: ifNotAuthenticated
  },
  // {
  //   path: '/reporting/event',
  //   name: 'reports/Event',
  //   beforeEnter: ifNotAuthenticated
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound'
  },
  {
    path: '/admin/sites/',
    name: 'admin/SitesAndSensors',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/admin/dashboard/',
    name: 'admin/Dashboard',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/admin/sites/:sensor_uuid',
    name: 'admin/UrisMoreDetails',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/admin/users',
    name: 'admin/UserManagement',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/admin/api',
    name: 'admin/API',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/admin/instance',
    name: 'admin/Instance',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/messages',
    name: 'MessageCenter',
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/auth/callback',
    name: 'Callback'
  }
]

const routes = routeOptions.map((route) => {
  return {
    ...route,
    component: () =>
      import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`)
  }
})

const router = new createRouter({
  history: createWebHistory(),
  routes
})

export default router
