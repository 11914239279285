<template>
  <div>
    <v-toolbar
      color="transparent"
      style="font-size:1.2em"
      class="pa-2 pb-0"
      v-if="title || search"
    >
      <h3 class="text-h6 font-weight-light text-primary">
        {{ title }}
      </h3>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="search"
        v-model="search_string"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details="auto"
        style="max-width:250px"
      ></v-text-field>
    </v-toolbar>

    <template v-if="certs.length < 6 && certs.length > 0">
      <v-table>
        <tr style="font-size:.8em;">
          <th style="border-bottom:1px solid #eee"></th>
          <th style="border-bottom:1px solid #eee">Subject</th>
          <th style="border-bottom:1px solid #eee">Valid From</th>
          <th style="border-bottom:1px solid #eee">Valid To</th>
          <th
            v-if="showDownloadButton"
            style="border-bottom:1px solid #eee"
          ></th>
        </tr>
        <tr v-for="c in certs" :key="c.sha256">
          <td>
            <CertTile :certificate="c" :local="local"></CertTile>
          </td>
          <td>
            <v-btn
              @mouseenter="$emit('hover', c.pki_certificate.sha256)"
              @mouseout="$emit('hover')"
              :to="`/certificate/${c.pki_certificate.sha256}`"
              variant="text"
              class="ma-0 pa-0"
              density="compact"
              size="small"
              color="primary"
            >
              <span
                class="pt-0 mt-0 text-subtitle-2 font-weight-bold text-primary"
              >
                {{ c.pki_certificate.friendly_name }}
              </span>
            </v-btn>
            <br />
            <span
              style="font-size:.8em;margin-left:1px;"
              class="font-weight-bold"
              >Issued by &nbsp;</span
            >
            <v-btn
              class="ma-0 px-1 pt-0 pb-6"
              variant="text"
              density="compact"
              size="x-small"
              color="transparent"
              to="#"
            >
              <span class="text-subtitle-2 font-weight-light text-primary">{{
                c.pki_certificate.issuer_friendly_name
              }}</span>
            </v-btn>
          </td>
          <td>
            <span
              v-if="beforeNow(c.pki_certificate.not_before)"
              class="text-subtitle-2"
            >
              {{
                $filters.dateTimeReadable(
                  c.pki_certificate.not_before,
                  'MMM D YYYY'
                )
              }}
            </span>
            <span
              v-else
              style="color:red;"
              class="text-subtitle-2 font-weight-bold"
            >
              {{
                $filters.dateTimeReadable(
                  c.pki_certificate.not_before,
                  'MMM D YYYY'
                )
              }}
            </span>
          </td>
          <td>
            <span
              v-if="afterNow(c.pki_certificate.not_after)"
              class="text-subtitle-2"
            >
              {{
                $filters.dateTimeReadable(
                  c.pki_certificate.not_after,
                  'MMM D YYYY'
                )
              }}
            </span>
            <span
              v-else
              style="color:red;"
              class="text-subtitle-2 font-weight-bold"
            >
              {{
                $filters.dateTimeReadable(
                  c.pki_certificate.not_after,
                  'MMM D YYYY'
                )
              }}
            </span>
          </td>
          <td v-if="showDownloadButton">
            <DownloadFileButton
              :sha256="c.pki_certificate.sha256"
              :filename="c.pki_certificate.friendly_name"
              fileExtension="cer"
            ></DownloadFileButton>
          </td>
        </tr>
      </v-table>
    </template>
    <template v-else>
      <v-data-table
        :headers="get_headers"
        density="compact"
        :items="certs"
        :search="search_string"
        class="elevation-0"
        style="border:0;background:transparent;"
        :sort-by="['valid_from']"
        :sort-desc="[true]"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 100]
        }"
        :options="{ itemsPerPage: page_size }"
        :mobile-breakpoint="0"
      >
        <template v-slot:no-data>
          <span>No data available</span>
        </template>
        <template v-slot:footer>
          huh
        </template>
        <template v-slot:item.pki_certificate.sha256="{ item }">
          <CertTile
            v-if="item"
            :certificate="item"
            :local="local"
          ></CertTile>
        </template>

        <template v-slot:item.pki_certificate.friendly_name="{ item }">
          <v-btn
            v-if="item"
            @mouseenter="$emit('hover', item.pki_certificate.sha256)"
            @mouseout="$emit('hover')"
            :to="`/certificate/${item.pki_certificate.sha256}`"
            variant="text"
            class="ma-0 pa-0"
            density="compact"
            size="small"
            color="primary"
          >
            <span
              class="pt-0 mt-0 text-subtitle-2 font-weight-bold text-primary"
            >
              {{ item.pki_certificate.friendly_name }}
            </span>
          </v-btn>
          <br />
          <span style="font-size:.8em;margin-left:1px;" class="font-weight-bold"
            >Issued by &nbsp;</span
          >
          <v-btn
            v-if="item"
            class="ma-0 px-1 pt-0 pb-6"
            variant="text"
            density="compact"
            size="x-small"
            color="transparent"
            to="#"
          >
            <span class="text-subtitle-2 font-weight-light text-primary">{{
              item.pki_certificate.issuer_friendly_name
            }}</span>
          </v-btn>
        </template>

        <template v-slot:item.pki_certificate.not_before="{ item }">
          <span
            v-if="beforeNow(item.pki_certificate.not_before)"
            class="text-subtitle-2"
          >
            {{
              $filters.dateTimeReadable(
                item.pki_certificate.not_before,
                'MMM D YYYY'
              )
            }}
          </span>
          <span
            v-else
            style="color:red;"
            class="text-subtitle-2 font-weight-bold"
          >
            {{
              $filters.dateTimeReadable(
                item.pki_certificate.not_before,
                'MMM D YYYY'
              )
            }}
          </span>
        </template>

        <template v-slot:item.pki_certificate.not_after="{ item }">
          <span
            v-if="afterNow(item.pki_certificate.not_after)"
            class="text-subtitle-2"
          >
            {{
              $filters.dateTimeReadable(
                item.pki_certificate.not_after,
                'MMM D YYYY'
              )
            }}
          </span>
          <span
            v-else
            style="color:red;"
            class="text-subtitle-2 font-weight-bold"
          >
            {{
              $filters.dateTimeReadable(
                item.pki_certificate.not_after,
                'MMM D YYYY'
              )
            }}
          </span>
        </template>

        <template
          v-if="showDownloadButton"
          v-slot:item.pki_certificate="{ item }"
        >
          <DownloadFileButton
            :sha256="item.pki_certificate.sha256"
            :filename="item.pki_certificate.friendly_name"
          ></DownloadFileButton>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import moment from 'moment/src/moment'
import CertTile from './CertTile'
import DownloadFileButton from './DownloadFileButton'

export default {
  name: 'CertificateTable',
  components: { CertTile, DownloadFileButton },
  props: {
    certificates: {
      type: Array, // Assuming it's an array of certificate objects
      required: true // Assuming it's mandatory to pass this prop
    },
    default_page_size: {
      type: Number,
      default: 10 // Assuming default page size is 10
    },
    display_type: {
      type: Number,
      default: 1 // Adjust default value as needed
    },
    local: {
      type: Boolean,
      default: true // Default to true, but adjust as needed
    },
    query: {
      type: String,
      default: '' // Default to an empty query string
    },
    search: {
      type: Boolean,
      default: false // Default to no search
    },
    showDownloadButton: {
      type: Boolean,
      default: false
    },
    expired: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '' // Default to an empty string
    }
  },
  emits: ['hover'],
  data: function() {
    return {
      search_string: '',
      filter: 'Show All',
      filters: ['Show All'],
      loading: false,
      options: { itemsPerPage: 10 },
      table_headers: [
        {
          title: '',
          sortable: false,
          key: 'pki_certificate.sha256',
          width: 70
        },
        {
          title: 'Subject',
          sortable: true,
          key: 'pki_certificate.friendly_name'
        },
        {
          title: 'Valid From',
          sortable: true,
          width: 100,
          key: 'pki_certificate.not_before'
        },
        {
          title: 'Valid To',
          sortable: true,
          width: 100,
          key: 'pki_certificate.not_after'
        }
      ],
      small_headers: [
        {
          title: '',
          sortable: false,
          key: 'pki_certificate.sha256',
          width: 70
        },
        {
          title: 'Subject',
          sortable: true,
          key: 'pki_certificate.friendly_name'
        }
      ]
    }
  },
  computed: {
    page_size: function() {
      if (this.default_page_size) {
        return this.default_page_size
      } else {
        return 10
      }
    },
    get_headers: function() {
      let headers = []
      console.log(this.display_type)
      if (this.display_type == 1) {
        headers = this.small_headers
      } else {
        if (this.$vuetify.display.smAndUp) {
          headers = this.table_headers
        } else {
          headers = this.small_headers
        }
      }
      if (this.showDownloadButton) {
        headers.push({
          title: '',
          sortable: false,
          key: 'pki_certificate.serial_number'
        })
      }
      console.log(headers)
      return headers
    },
    certs() {
      let _certificates = []
      if (this.certificates) {
        _certificates = this.certificates
      } else {
        let certSettings = this.$store.getters.certificate_settings
        if (certSettings) {
          _certificates = certSettings.map((e) => e.certificate)
        } else {
          _certificates = []
          return _certificates
        }
      }
      if (!this.expired) {
        let excludedExpiredCertificates = _certificates.map((certificate) => {
          if (this.afterNow(certificate.pki_certificate.not_after))
            return certificate
        })
        _certificates = excludedExpiredCertificates
      }
      return _certificates
    }
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment())
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment())
    },
    showCertDialog: function(cert) {
      this.$store.dispatch('show_certificate_dialog', cert)
    }
  },
  watch: {}
}
</script>
